var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.65.8-9a07c4e46"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'
import { digest } from 'src/api/digest'
import { App } from 'src/api/firebase'
import storage from 'src/api/storage'
import {
  OrganizationModel,
  RoomModel,
  TeamModel,
  UserModel,
  BrowserVersionModel,
} from '@gijirokukun/shared'
import { browserName, browserVersion } from 'react-device-detect'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_RELEASE =
  process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE

function getEnviroment() {
  if (typeof location === 'undefined') {
    return 'next-ssg'
  } else if (location.hostname === 'localhost') {
    return 'localhost'
  } else if (location.hostname.startsWith('minutes-maker-dev')) {
    return 'development'
  } else if (location.hostname === 'gijirokukun.com') {
    return 'production'
  } else {
    return `unknown-${process.env.NEXT_PUBLIC_ENV ?? 'unknown'}`
  }
}

let hasBeenOfflineMoreThanOnce = false
if (typeof window !== 'undefined') {
  window.addEventListener('offline', () => {
    console.debug('detect offline')
    hasBeenOfflineMoreThanOnce = true
  })
}

let hasBeenUnLoaded = false
if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', () => {
    console.log('detect unload')
    hasBeenUnLoaded = true
  })
}

let hasBeenFailedToDatabaseCheck = false

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://571cb9a5d3914fdcb24ab45a2f4e8639@o934455.ingest.sentry.io/5883799',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: SENTRY_RELEASE,

  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  environment: getEnviroment(),

  async beforeSend(event, hint) {
    event.tags['problem'] = 'unset'

    try {
      const message = event.message ?? hint.originalException?.toString()

      /**
       * false positiveなエラーの除去
       */

      // SSG時のエラーはコンパイル時に検知できるはず
      if (getEnviroment() === 'next-ssg') {
        return null
      }

      // オフラインになったことがあるか、ページがアンロードされていれば無視する（無意味なエラーが送られてしまうことが多いため）
      if (navigator.onLine !== true) {
        //throw new Error('page is offline')
        return null
      }
      if (hasBeenOfflineMoreThanOnce) {
        //throw new Error('page has been offline more than once')
        return null
      }
      if (hasBeenUnLoaded) {
        //throw new Error('page has been unloaded')
        return null
      }

      // ログアウト直後なら無視
      const lastLogoutAt = storage.get('lastLogoutAt')
      if (lastLogoutAt != null) {
        console.debug(Date.now(), lastLogoutAt)
        if (Date.now() - lastLogoutAt < 5 * 60 * 1000) {
          //throw new Error('when logout')
          return null
        }
      }

      // 開発時のコンパイルエラーやそれに類するエラーは雑音なので送信しない
      if (['localhost', 'next-ssg'].includes(getEnviroment())) {
        if (hint) {
          const error = hint.originalException
          if (error instanceof TypeError || error instanceof ReferenceError) {
            return null
          }
          // これはfalse negativeがあるだろうがどちらにしろ分かりづらいし妥協する
          if (error != null && error.toString().startsWith('The above error')) {
            return null
          }
          if (error != null && error.toString().includes('Syntax error')) {
            return null
          }
        }
      }

      // データベースのチェック
      try {
        if (hasBeenFailedToDatabaseCheck) {
          // 一度データベースのチェックに失敗していたらApp checkの失敗やfalse positive、大量のエラーによる大量のfirestoreアクセスを避けるためスキップする
          //throw new Error('user has been failed to database check, skip')
          return null
        }
        const uid = App.auth.currentUser?.uid
        if (uid) {
          // ユーザーの属性のチェック
          const userInfoReadonly =
            await UserModel.getInfoReadonlyDocRef(uid).get()
          const userInfoReadonlyData = userInfoReadonly.data()
          if (userInfoReadonlyData?.belonged_organization_ref != null) {
            const member = await OrganizationModel.getMembersRef(
              userInfoReadonlyData.belonged_organization_ref
            )
              .doc(uid)
              .get()
            if (member.data().active !== true) {
              // ユーザーが組織に属していて無効なら組織周りのfalse positiveなエラーが大量に発生するのでスキップ
              console.debug('skip deactive org user')
              hasBeenFailedToDatabaseCheck = true
              //throw new Error('user is inactive')
              return null
            } else {
              event.tags['debugDatabaseCheck'] = 'activeOrgMember'
            }
          } else {
            event.tags['debugDatabaseCheck'] = 'nonOrgMember'
          }

          // 削除されたコンテンツにいる場合は無視する
          if (window.location.pathname === '/comments/') {
            const roomID = new URLSearchParams(window.location.search).get('id')
            try {
              const room = await RoomModel.roomsRef().doc(roomID).get()
              if (!room.exists || room.data().deleted) {
                return null
              }
            } catch (_) {
              //throw new Error('user is in deleted comment page')
              return null
            }
          }
          if (window.location.pathname === '/team/') {
            const teamID = new URLSearchParams(window.location.search).get('id')
            try {
              await TeamModel.ref().doc(teamID).get()
            } catch (_) {
              //hrow new Error('user is in deleted team page')
              return null
            }
          }

          // ブラウザのバージョンが古ければ無視
          const newerBrowserVersions = (
            await BrowserVersionModel.ref()
              .where('browserName', '==', browserName)
              .orderBy('browserVersion', 'desc')
              .limit(3)
              .get()
          ).docs.map((snap) => snap.data().browserVersion)
          event.tags['isOldBrowser'] = 'false'
          if (newerBrowserVersions.length < 3) {
            await BrowserVersionModel.ref()
              .doc(`${browserName}-${browserVersion}`)
              .set({
                browserName: browserName,
                browserVersion: Number(browserVersion),
              })
          } else if (
            Number(browserVersion) < Math.min(...newerBrowserVersions)
          ) {
            console.debug('detect old browser')
            event.tags['isOldBrowser'] = 'true'
          } else if (!newerBrowserVersions.includes(Number(browserVersion))) {
            await BrowserVersionModel.ref()
              .doc(`${browserName}-${browserVersion}`)
              .set({
                browserName: browserName,
                browserVersion: Number(browserVersion),
              })
          }
        } else {
          // ※ 未ログインユーザーについては、false positiveが多かったらまた対策を考える。一旦はスルー
          event.tags['debugDatabaseCheck'] = 'currentUserNotFound'
        }
      } catch (error) {
        // Firestoreへのアクセスに失敗したらネットワーク接続に失敗している可能性が高いのでスキップ
        console.debug('failed to access firestore, skip', error)
        hasBeenFailedToDatabaseCheck = true
        // throw new Error('failed to access firestore', {
        //   cause: error,
        // })
        return null
      }

      // 特定のエラーメッセージを除去

      let errorMessageCandidates = []
      if (event.message) {
        errorMessageCandidates.push(event.message)
      }
      if (hint && hint.originalException != null) {
        errorMessageCandidates.push(hint.originalException.message)
      }

      const ignoreMessages = [
        // クラッシュメッセージは識別の邪魔なので無視する
        'A client-side exception has occurred, see here for more info: https://nextjs.org/docs/messages/client-side-exception-occurred',
        // false positiveなエラーを無視する（大量に送信するとlimitになってしまう）
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        'Non-Error promise rejection captured with value: cancelled',
      ]

      for (let ignoreMessage of ignoreMessages) {
        for (let errorMessage of errorMessageCandidates) {
          if (errorMessage === ignoreMessage) {
            console.debug('Error ignored', errorMessage)
            return null
          }
        }
      }

      /**
       * エラー送信の前処理
       */

      // イベントのハッシュを登録してSentryとHotjarを結びつける
      // let eventHash = undefined
      // if (message != null && message !== '') {
      //   eventHash = await digest(message)
      //   event.tags['eventHash'] = eventHash
      // }

      // Hotjarにイベントを送信
      // hotjar.event('error')
      // if (eventHash != null) {
      //   hotjar.event(`error_${eventHash}`)
      // }
    } catch (error) {
      event.tags['problem'] = String(error)
    }

    return event
  },
})
